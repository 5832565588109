import React, { useState, useEffect } from "react";
import {
  Connection,
  PublicKey,
  Transaction,
  SystemProgram,
} from "@solana/web3.js";
import { getDatabase, ref, get, update } from "firebase/database";
import { ToastContainer, toast } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css"; 

const WalletTransaction = ({ username, onTransactionComplete }) => {
  const [walletAddress, setWalletAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState("");
  const [paymentCompleted, setPaymentCompleted] = useState(false); 

  useEffect(() => {
    const fetchUserData = async () => {
      const db = getDatabase();
      const userRef = ref(db, `users/${username}`);

      // User payment status
      try {
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setPaymentCompleted(userData.paymentCompleted || false);
        } else {
          console.error("User not found");
        }
      } catch (error) {
        console.error("Firebase data error:", error);
      }
    };

    fetchUserData();
  }, [username]);

  const handleWalletAddressChange = (e) => {
    setWalletAddress(e.target.value);
  };

  const handleTransaction = async () => {
    setLoading(true);
    try {
      const db = getDatabase();
      const userRef = ref(db, `users/${username}`);
  
      if (paymentCompleted) {
        // payments already payed, just save address
        await update(userRef, { walletAddress: walletAddress });
        setTransactionStatus("Wallet address saved successfully.");
        toast.success("Wallet address saved successfully!");
      } else {
        // If payment has not been made,start the payment process
        const connection = new Connection("https://mainnet.helius-rpc.com/?api-key=d08a3151-4d9a-4e86-9d34-2bda287edaf4");
        const fromWallet = window.solana;
        const toPublicKey = new PublicKey("4NindcMVMRGBLPWQzWzVxVguu8Z3ehcpexhdBQjdx29L");
  
        if (!fromWallet || !fromWallet.publicKey) {
          throw new Error("Connect Wallet.");
        }
  
        const { blockhash } = await connection.getLatestBlockhash();
  
        const transaction = new Transaction().add(
          SystemProgram.transfer({
            fromPubkey: fromWallet.publicKey,
            toPubkey: toPublicKey,
            lamports: 1000000, // 1 SOL = 1.000.000 lamports
          })
        );
  
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = fromWallet.publicKey;
  
        const { signature } = await fromWallet.signAndSendTransaction(transaction);
        await connection.confirmTransaction(signature);
  
        setTransactionStatus("Transaction successful!!");
  
        // Update wallet address and payment status after payment is completed
        await update(userRef, {
          walletAddress: walletAddress,
          paymentCompleted: true,
        });
        toast.success("Transaction successful and wallet address saved!");
      }
    } catch (error) {
      setTransactionStatus("Transfer failed: " + error.message);
      toast.error("Transfer failed: " + error.message);
      console.error("Solana process error: ", error);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div>
      <input
        type="text"
        placeholder="Enter Solana Wallet"
        value={walletAddress}
        onChange={handleWalletAddressChange}
        disabled={loading}
      />
      <button
        className="save-btn"
        onClick={handleTransaction}
        disabled={loading || !walletAddress}
      >
        {loading ? "Transaction in progress..." : "Save"}
      </button>
      <p>{transactionStatus}</p>
      <ToastContainer autoClose={5000} />{" "}
    </div>
  );
};

export default WalletTransaction;
