import React from "react";
import TweetAndScore from "./components/TweetAndScore";
import GenerateCode from "./components/RefGenerate/RefGenerate";
import Header from "./components/Header";
import ApplyRefCode from "./components/ReferalInput/ReferralInput";
import TweetList from "./components/TweetList/TweetList";
import Leaderboard from "./components/Leaderboard/Leaderboard";
import Sidebar from "./components/Sidebar/Sidebar";
import Tasks from "./components/Task/Tasks";
import { ToastContainer } from 'react-toastify'; 
import "./App.css"; 

function App() {
  return (
    <div>
      <ToastContainer /> 
      <Header />
      <div className="main-container">
        <div className="sidebar-section">
          <Sidebar />
        </div>
        <div className="main">
          <div className="x-section">
            <div className="share-post">
              <TweetAndScore />
            </div>

            <div className="ref-section">
              <GenerateCode />
              <ApplyRefCode />
            </div>
          </div>
          <div className="users-section">
            <div className="leaderboard">
              <Leaderboard />
            </div>
            <div className="tasks">
              <Tasks />
            </div>
          </div>
        </div>
        <div className="last-posts">
          <TweetList />
        </div>
      </div>
    </div>
  );
}

export default App;
