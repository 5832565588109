import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue } from "firebase/database";
import "./Leaderboard.css";

const Leaderboard = () => {
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null); 
  const [isOpen, setIsOpen] = useState(false);
  const [userPosition, setUserPosition] = useState(null); 
  const [currentUserPoints, setCurrentUserPoints] = useState(0); 

  useEffect(() => {
    const db = getDatabase();
    const usersRef = ref(db, "users");

    onValue(usersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
       
        const usersArray = Object.keys(data)
          .map((key) => ({
            username: key,
            points: data[key].points || 0,
          }))
          .sort((a, b) => b.points - a.points); 

        setUsers(usersArray.slice(0, 15)); 

       
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
          if (user) {
            const twitterScreenName = user.reloadUserInfo.screenName;
            setCurrentUser(twitterScreenName);

            
            const position =
              usersArray.findIndex((u) => u.username === twitterScreenName) + 1;
            setUserPosition(position);

            
            const currentUserData = usersArray.find(
              (u) => u.username === twitterScreenName
            );
            if (currentUserData) {
              setCurrentUserPoints(currentUserData.points);
            } else {
              
              const allUsers = Object.keys(data).map((key) => ({
                username: key,
                points: data[key].points || 0,
              }));
              const userInAllUsers = allUsers.find(
                (u) => u.username === twitterScreenName
              );
              if (userInAllUsers) {
                setCurrentUserPoints(userInAllUsers.points);
              }
            }
          }
        });
      }
    });
  }, []);

  return (
    <div className="leaderboard-container">
      <div>
        <div>
          <h2>Leaderboard</h2>

         
          <ul className="leaderboard-list">
            {users.map((user, index) => (
              <li key={user.username} className="leaderboard-item">
                <span className="leaderboard-username">
                  {index + 1}. {user.username}
                </span>
                <span className="leaderboard-points">{user.points} pts</span>
              </li>
            ))}
          </ul>

          {currentUser && userPosition > 10 && (
            <>
              <div className="spacer"></div>
              <div className="current-user">
                <p>
                  Your Rank: {userPosition}. {currentUser} - {currentUserPoints}{" "}
                  pts
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
