import React, { useState, useEffect } from "react";
import {
  generateAndSaveReferralCode,
  getReferralCode,
  getReferralCount,
} from "../firebase-config"; 
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "./RefGenerate.css";

const ReferralCodeButton = () => {
  const [referralCode, setReferralCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [screenName, setScreenName] = useState(""); 
  const [referralCount, setReferralCount] = useState(0); 

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const twitterScreenName = user.reloadUserInfo.screenName;
        setScreenName(twitterScreenName);

        // user current code
        try {
          let existingCode = await getReferralCode(twitterScreenName);
          if (existingCode) {
            setReferralCode(existingCode); // ref code show
          }

          // User ref count
          const count = await getReferralCount(twitterScreenName);
          setReferralCount(count);
        } catch (err) {
          console.error("Error fetching referral data", err);
          setError("Error fetching referral data");
        }
      } else {
        console.error("User is not logged in");
      }
    });

    return () => unsubscribe(); // Cleanup on unmount
  }, []);

  const handleGenerateCode = async () => {
    if (!screenName) {
      setError("Twitter screen name is missing");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const code = await generateAndSaveReferralCode(screenName);
      setReferralCode(code); // Set newly generated code
    } catch (err) {
      setError("Error generating referral code");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="generate-container">
      {referralCode && (
        <div className="ref-code">
          <h3>Referral Code</h3>
          <p>{referralCode}</p>
        </div>
      )}

      {!referralCode && (
        <button
          className="generate-btn"
          onClick={handleGenerateCode}
          disabled={loading}
        >
          {loading ? "Generating..." : "Generate Referral Code"}
        </button>
      )}
      <div className="ref-count">
        <h3>Referred</h3>
        <p>{referralCount}</p>
      </div>
    </div>
  );
};

export default ReferralCodeButton;
