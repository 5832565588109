import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue, update } from "firebase/database";
import { updatePoints } from "../firebase-config";
import "../Task/Tasks.css"; 

const TASKS = [
  {
    id: 1,
    description: "Follow Elpida on X",
    url: "https://x.com/intent/follow?screen_name=Elpidaxyz",
    points: 15,
  },
  {
    id: 2,
    description: "Retweet our latest post",
    url: "https://x.com/intent/retweet?tweet_id=1847673074130338106&related=twitterapi%2Ctwittermedia%2Ctwitter%2Csupport&original_referer=https%3A%2F%2Fdeveloper.x.com%2Fen%2Fdocs%2Fx-for-websites%2Fweb-intents%2Foverview#retweet-a-tweet",
    points: 15,
  },
  {
    id: 3,
    description: "Like our latest post",
    url: "https://x.com/intent/like?tweet_id=1847673074130338106&original_referer=https%3A%2F%2Fdeveloper.x.com%2Fen%2Fdocs%2Fx-for-websites%2Fweb-intents%2Foverview#retweet-a-tweet",
    points: 15,
  },
  // another tasks will add
];

const Tasks = () => {
  const [screenName, setScreenName] = useState("");
  const [points, setPoints] = useState(0);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [isOpen, setIsOpen] = useState(false); 

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const twitterScreenName = user.reloadUserInfo.screenName;
        if (twitterScreenName) {
          setScreenName(twitterScreenName);

          const db = getDatabase();
          const userRef = ref(db, "users/" + twitterScreenName);
          onValue(userRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
              setPoints(data.points || 0);
              setCompletedTasks(data.completedTasks || []);
            } else {
              updatePoints(twitterScreenName, 0, []);
            }
          });
        }
      }
    });
  }, []);

  const handleCompleteTask = async (taskId) => {
    if (screenName && !completedTasks.includes(taskId)) {
      const task = TASKS.find((t) => t.id === taskId);
      if (task) {
        const newPoints = points + task.points;
        const newCompletedTasks = [...completedTasks, taskId];
        setPoints(newPoints);
        setCompletedTasks(newCompletedTasks);

        const db = getDatabase();
        const userRef = ref(db, "users/" + screenName);
        await update(userRef, {
          points: newPoints,
          completedTasks: newCompletedTasks,
        });

        console.log(
          `User add ${task.points} puan point and tasks successfully!`
        );
      }
    } else {
      console.log("You have already completed this task.");
    }
  };

  return (
    <div>
      <div className="task-section">
        <h2>Tasks</h2>
        {TASKS.map((task) => (
          <div key={task.id} className="task">
            <p>{task.description}</p>
            <a
              href={task.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleCompleteTask(task.id)}
              className={`task-button ${
                completedTasks.includes(task.id) ? "disabled" : ""
              }`}
              disabled={completedTasks.includes(task.id)}
            >
              {completedTasks.includes(task.id) ? "Completed" : "Complete"}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Tasks;
