import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue, update } from "firebase/database";
import { updatePoints, saveTweetText } from "./firebase-config";

const TweetAndScore = () => {
  const [screenName, setScreenName] = useState("");
  const [points, setPoints] = useState(0);
  const [tweetText, setTweetText] = useState("");
  const [dailyTweetCount, setDailyTweetCount] = useState(0);
  const [warningMessage, setWarningMessage] = useState("");
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false); 
  const dailyLimit = 15;

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const twitterScreenName = user.reloadUserInfo.screenName;
        if (twitterScreenName) {
          setScreenName(twitterScreenName);
          setIsUserAuthenticated(true); 
          
          const db = getDatabase();
          const userRef = ref(db, "users/" + twitterScreenName);
          onValue(userRef, (snapshot) => {
            const data = snapshot.val();
            if (data && data.points !== undefined) {
              setPoints(data.points);
            } else {
              updatePoints(twitterScreenName, 0);
            }

            const dailyCount = data.dailyTweetCount || 0;
            setDailyTweetCount(dailyCount);

            const lastResetDate = data.lastResetDate || "";
            const today = new Date().toLocaleDateString();

            if (lastResetDate !== today) {
              update(ref(db, "users/" + twitterScreenName), {
                dailyTweetCount: 0,
                lastResetDate: today,
              });
              setDailyTweetCount(0);
            }
          });
        } else {
          setIsUserAuthenticated(false);
        }
      } else {
        setIsUserAuthenticated(false); 
      }
    });
  }, []);

  const handleTweet = async () => {
    const db = getDatabase();
    const today = new Date().toLocaleDateString();

    const lastResetDateRef = ref(db, "users/" + screenName + "/lastResetDate");
    onValue(lastResetDateRef, async (snapshot) => {
      const lastResetDate = snapshot.val() || "";

      if (lastResetDate !== today) {
        await update(ref(db, "users/" + screenName), {
          dailyTweetCount: 0,
          lastResetDate: today,
        });
        setDailyTweetCount(0);
      }

      if (dailyTweetCount >= dailyLimit) {
        setWarningMessage(
          `You have reached your daily post limit. Come back tomorrow.`
        );
        return;
      }

      try {
        if (screenName && tweetText) {
          const encodedTweetText = encodeURIComponent(tweetText);
          window.open(
            `https://twitter.com/intent/tweet?text=${encodedTweetText}`,
            "_blank"
          );

          await saveTweetText(screenName, tweetText);
          setTweetText("");

          let newPoints = points + 10;

          // Check ticker for extra point
          const extraKeywords = ["$SOL", "WAO", "wao"];
          extraKeywords.forEach((keyword) => {
            if (tweetText.includes(keyword)) {
              newPoints += 20; 
              console.log(`Extra point added: ${keyword}`);
            }
          });

          setPoints(newPoints);
          await updatePoints(screenName, newPoints);

          await updateDailyTweetCount(screenName, dailyTweetCount + 1);
          setDailyTweetCount(dailyTweetCount + 1);

          setWarningMessage(""); 
          console.log(
            "User points, tweet text, and daily tweet count updated successfully"
          );
        } else {
          console.error("Screen name or tweet text is missing");
        }
      } catch (error) {
        console.error("Error sending tweet or updating points:", error);
      }
    });
  };

  const updateDailyTweetCount = async (username, count) => {
    const db = getDatabase();
    await update(ref(db, "users/" + username), { dailyTweetCount: count });
  };

  return (
    <div>
      <div className="post-share">
        <h2>Share Post</h2>
        <textarea
          value={tweetText}
          onChange={(e) => setTweetText(e.target.value)}
          placeholder="What are you thinking right now?"
          disabled={!isUserAuthenticated} 
        />
        <button
          className="primary-btn"
          onClick={handleTweet}
          disabled={!isUserAuthenticated}
        >
          Send{" "}
        </button>
      </div>

      <div className="post-count">
        <p>
          Daily Post: {dailyTweetCount} / {dailyLimit}
        </p>
      </div>
      {warningMessage && (
        <div className="warning-message">
          <p>{warningMessage}</p>
        </div>
      )}
      {!isUserAuthenticated && (
        <p>Please log in to share Post.</p>  
      )}
    </div>
  );
};

export default TweetAndScore;
