// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, TwitterAuthProvider, signInWithPopup } from "firebase/auth";
import { getDatabase, ref, set, get, update, push } from "firebase/database";
import { getFirestore, collection, doc } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBftrePbmgG_0_0X416pOj14LBY33Xlpjk",
  authDomain: "blinks-ba71f.firebaseapp.com",
  databaseURL: "https://blinks-ba71f-default-rtdb.firebaseio.com",
  projectId: "blinks-ba71f",
  storageBucket: "blinks-ba71f.appspot.com",
  messagingSenderId: "373904021074",
  appId: "1:373904021074:web:94cd17db35a93c0c68b18b",
  measurementId: "G-XR3S720JKJ",
};

// start firebase
const app = initializeApp(firebaseConfig);

// get Auth and Database ref
const auth = getAuth(app);

const db = getDatabase(app);

// Initialize Firebase
const provider = new TwitterAuthProvider();
const database = getDatabase(app);

//REF CODE GENERATION
export const generateAndSaveReferralCode = async (screenName) => {
  const userRef = ref(db, "users/" + screenName);

  try {
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      if (!data.referralCode) {
        await set(userRef, {
          ...data,
          referralCode: screenName, // refCode = Handle
        });
        return screenName; 
      }
      return data.referralCode;
    } else {
      await set(userRef, {
        username: screenName,
        referralCode: screenName,
        points: 0,
      });
      return screenName; 
    }
  } catch (error) {
    console.error("Error generating or saving referral code:", error);
    throw error;
  }
};

// Firebase uesr ref count
export const getReferralCount = async (screenName) => {
  const db = getDatabase();
  const userRef = ref(db, `users/${screenName}/referralsUsedBy`);

  try {
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      const referrals = snapshot.val();

      return referrals ? Object.keys(referrals).length : 0;
    } else {
      return 0; 
    }
  } catch (error) {
    console.error("Error fetching referral count", error);
    return 0;
  }
};

// User Tweet content
export const saveTweetText = async (screenName, tweetText) => {
  const db = getDatabase();
  const userTweetsRef = ref(db, `tweets/${screenName}`); 
  const newTweetRef = push(userTweetsRef); 
  await set(newTweetRef, {
    tweet: tweetText,
    timestamp: Date.now(), // Timestamp
  });
};

// get ref code
export const getReferralCode = async (screenName) => {
  const userRef = ref(db, "users/" + screenName);

  try {
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      if (data.referralCode) {
        return data.referralCode; 
      } else {
        console.log("Referral code not found");
        return null;
      }
    } else {
      console.log("User not found");
      return null; 
    }
  } catch (error) {
    console.error("Error fetching referral code:", error);
    return null; 
  }
};

// firebase ref code apply
export const applyReferralCode = async (referralCode, currentUser) => {
  const userRef = ref(db, "users/" + currentUser);
  let referrerRef = null;

  try {
    const userSnapshot = await get(userRef);
    if (!userSnapshot.exists()) {
      console.error("User not found");
      return;
    }

    const userData = userSnapshot.val();

    // check user ref code apply before
    if (userData.usedReferralCodes && userData.usedReferralCodes.length > 0) {
      console.error("User has already used a referral code");
      return;
    }

    if (
      userData.usedReferralCodes &&
      userData.usedReferralCodes.includes(referralCode)
    ) {
      console.error("User has already used this referral code");
      return;
    }

    // Find the owner of the referral code by browsing through all users
    const allUsersRef = ref(db, "users");
    const allUsersSnapshot = await get(allUsersRef);

    allUsersSnapshot.forEach((childSnapshot) => {
      const childData = childSnapshot.val();
      if (childData.referralCode === referralCode) {
        if (childSnapshot.key === currentUser) {
          console.error("User cannot use their own referral code");
          referrerRef = null; // Don't let use own code
          return;
        }
        referrerRef = ref(db, `users/${childSnapshot.key}`);
      }
    });

    if (!referrerRef) {
      console.error("Referrer not found");
      return;
    }

    // Current user add point and marked
    const newUserPoints = (userData.points || 0) + 5;
    await update(userRef, {
      points: newUserPoints,
      usedReferralCodes: [...(userData.usedReferralCodes || []), referralCode], // Saved that the user used a referral code
    });

    // Add points to the owner of the referral code
    const referrerSnapshot = await get(referrerRef);
    const referrerData = referrerSnapshot.val();
    const newReferrerPoints = (referrerData.points || 0) + 10;
    const updatedReferralsUsedBy = [
      ...(referrerData.referralsUsedBy || []),
      currentUser,
    ]; // Add to users list

    await update(referrerRef, {
      points: newReferrerPoints,
      referralsUsedBy: updatedReferralsUsedBy, // Update users list
    });

    console.log("Referral code applied successfully");
  } catch (error) {
    console.error("Error applying referral code:", error);
  }
};

const signInWithTwitter = () => signInWithPopup(auth, provider);

export const updatePoints = (screenName, points) => {
  const userRef = ref(db, "users/" + screenName);
  return update(userRef, {
    points: points,
  });
};

export { auth, provider, signInWithPopup, database, signInWithTwitter };
