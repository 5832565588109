import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, get, update } from "firebase/database";
import { Wallet } from "./LoginBtn/Adapter";
import TwitterLogin from "./LoginBtn/TwitterLogin";
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';

const Header = () => {
  const [username, setUsername] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [pointsAwarded, setPointsAwarded] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const screenName = user.reloadUserInfo.screenName;
        setUsername(screenName);

        const db = getDatabase();
        const userRef = ref(db, `users/${screenName}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          if (userData.pointsAwarded) {
            setPointsAwarded(true);
          }
        }
      } else {
        console.log("User not logged in");
        setUsername(null);
      }
    });
  }, []);

  const updatePointsInFirebase = async () => {
    const db = getDatabase();
    if (username) {
      const userRef = ref(db, `users/${username}`);
      try {
        const snapshot = await get(userRef);
        let currentPoints = snapshot.exists() ? snapshot.val().points : 0;
        const newPoints = currentPoints + 10;
        await update(userRef, { points: newPoints, pointsAwarded: true });
        console.log(`Points updated: ${newPoints}`);
        toast.success(`Points updated: ${newPoints}`); // noti
      } catch (error) {
        console.error("Score could not be updated: ", error);
        toast.error("Score could not be updated!"); 
      }
    }
  };

  const handleImageClick = () => {
    if (!pointsAwarded && username) {
      setClickCount((prevCount) => prevCount + 1);
    }
  };

  useEffect(() => {
    if (clickCount === 20 && !pointsAwarded && username) {
      updatePointsInFirebase(); 
      setPointsAwarded(true);
    }
  }, [clickCount, pointsAwarded, username]);

  return (
    <div className="header">
      <ToastContainer className="Toastify" /> 
      <div className="header-info">
        <img
          className="logo"
          src="logo-white1.png"
          title=""
          alt="Logo"
          onClick={handleImageClick}
        />
        <h2>GM! @{username ? username : "Guest"}</h2>
      </div>
      <div className="header-btn">
        <TwitterLogin />
        <Wallet />
      </div>
    </div>
  );
};

export default Header;
