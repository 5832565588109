import React from "react";
import Tasks from "../Task/Tasks";
import Leaderboard from "../Leaderboard/Leaderboard";
import "../Sidebar/Sidebar.css";

const Sidebar = () => {
  return (
    <div>
      <div className="sidebar-container">
       <div className="socials">
          <a href="https://x.com/elpidaxyz" target="_blank"><i className="fa-brands fa-twitter"></i></a>
          <a href="#"><i className="fa-brands fa-discord"></i></a>
          <a href="#"><i className="fa-brands fa-medium"></i></a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
