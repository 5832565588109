import React, { useState, useEffect } from "react";
import { applyReferralCode } from "../firebase-config"; 
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, get, update } from "firebase/database";
import Modal from "../Modal/Modal";
import WalletTransaction from "../WalletTransaction"; 
import "./ReferralInput.css";

const ReferralInput = () => {
  const [referralCode, setReferralCode] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false); 

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        console.error("No user is signed in");
      }
    });

    return () => unsubscribe();
  }, []);

  const handleReferralSubmit = async () => {
    if (!currentUser || !currentUser.reloadUserInfo.screenName) {
      setError("Current user or display name is missing");
      console.error("Current user or display name is missing");
      return;
    }

    if (referralCode) {
      try {
        await applyReferralCode(
          referralCode,
          currentUser.reloadUserInfo.screenName
        );
        console.log("Referral code used successfully");
      } catch (error) {
        setError("Error applying referral code");
        console.error("Error applying referral code:", error);
      }
    } else {
      setError("Please enter a referral code");
      console.error("Please enter a referral code");
    }
  };

  return (
    <div className="refInput-container">
      <div className="ref-input">
        <input
          type="text"
          value={referralCode}
          onChange={(e) => setReferralCode(e.target.value)}
          placeholder="Enter referral code"
        />
        <button className="input-btn" onClick={handleReferralSubmit}>
          Use Referral Code
        </button>
      </div>
      <div className="save-add">
        <h3 className="ticker"> Ticker: $SOL </h3>
        {/* <button onClick={() => setShowModal(true)}>Save Your Wallet</button> */}
      </div>
      {showModal && currentUser && currentUser.reloadUserInfo.screenName && (
        <Modal onClose={() => setShowModal(false)}>
          <WalletTransaction
            username={currentUser.reloadUserInfo.screenName} 
            onTransactionComplete={() => setShowModal(false)} 
          />
        </Modal>
      )}
    </div>
  );
};

export default ReferralInput;
