import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import "./TweetList.css";

const TweetList = () => {
  const [tweets, setTweets] = useState([]);

  useEffect(() => {
    const db = getDatabase();
    const tweetsRef = ref(db, "tweets");

    const unsubscribe = onValue(tweetsRef, (snapshot) => {
      const tweetsData = snapshot.val();
      if (tweetsData) {
        const allTweets = [];
        Object.keys(tweetsData).forEach((username) => {
          const userTweets = tweetsData[username];
          Object.keys(userTweets).forEach((tweetId) => {
            allTweets.push({
              id: tweetId,
              username: username,
              ...userTweets[tweetId],
            });
          });
        });
        // sort by time
        allTweets.sort((a, b) => b.timestamp - a.timestamp);
        setTweets(allTweets.slice(0, 7)); // last 7 post
      } else {
        setTweets([]); 
      }
    });

    // Cleanup function to unsubscribe from the listener
    return () => unsubscribe();
  }, []);

  const getFirstHundredWords = (text) => {
    const words = text.split(" ");
    return words.slice(0, 100).join(" ") + (words.length > 100 ? "..." : "");
  };
  
  return (
    <div className="tweet-list">
      <h2>Recent Tweets</h2>
      <ul>
        {tweets.map((tweet) => (
          <li key={tweet.id}>
            <strong>{tweet.username}:</strong>
            <div className="tweet-content">
              {getFirstHundredWords(tweet.tweet)}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TweetList;
